<template>
  <v-card id="cw-confirmation-dialog--dialog__application-unfinished">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        Close the agreement without signing
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p
        v-translate="{
          openingTag: '<b>',
          closingTag: '</b>',
          expirationDate,
        }"
        render-html="true"
      >
        You can return to sign the loan agreement at any time by
        %{ openingTag }%{ expirationDate }%{ closingTag }.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__cancel"
        color="primary"
        text
        @click="
          $emit('dialog-cancel');
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Cancel
        </translate>
      </v-btn>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          goToUrl();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Close
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwDialogApplicationUnfinished',

  computed: {
    ...mapGetters({
      expirationDate: 'application/expirationDate',
    }),
  },

  methods: {
    goToUrl() {
      window.location.replace('https://www.saldo.com');
    },
  },
};
</script>
